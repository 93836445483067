export const LABEL_GTM = "Google Tag Manager";
export const LABEL_MTM = "Matomo Tag Manager";
export const PRESET_ID_GOOGLE_TAG_MANAGER = "gtm";
export const PRESET_ID_MATOMO_TAG_MANAGER = "mtm";
export const PRESET_ID_TAG_MANAGERS = [
    PRESET_ID_GOOGLE_TAG_MANAGER,
    PRESET_ID_MATOMO_TAG_MANAGER
];
function getManagerDependingVariables(setCookiesViaManager, param) {
    let { presetId, isGcm } = param;
    let useGlobalObjectName;
    let getDataLayer;
    let serviceIsManager = false;
    let managerLabel = "";
    let expectedManagerPresetId;
    const features = {
        events: true,
        executeCodeWhenNoTagManagerConsentIsGiven: true
    };
    let useManager = setCookiesViaManager || "none";
    if (useManager === "googleTagManagerWithGcm" && !isGcm) {
        useManager = "googleTagManager";
    }
    switch(useManager){
        case "googleTagManager":
        case "googleTagManagerWithGcm":
            expectedManagerPresetId = PRESET_ID_GOOGLE_TAG_MANAGER;
            useGlobalObjectName = "dataLayer";
            managerLabel = LABEL_GTM;
            features.events = useManager !== "googleTagManagerWithGcm";
            break;
        case "matomoTagManager":
            expectedManagerPresetId = PRESET_ID_MATOMO_TAG_MANAGER;
            useGlobalObjectName = "_mtm";
            managerLabel = LABEL_MTM;
            break;
        default:
            features.events = false;
            features.executeCodeWhenNoTagManagerConsentIsGiven = false;
            break;
    }
    if (useGlobalObjectName) {
        getDataLayer = ()=>{
            window[useGlobalObjectName] = window[useGlobalObjectName] || [];
            return window[useGlobalObjectName];
        };
    }
    if (expectedManagerPresetId && presetId === expectedManagerPresetId) {
        serviceIsManager = true;
        features.events = false;
        features.executeCodeWhenNoTagManagerConsentIsGiven = false;
    }
    return {
        getDataLayer,
        useManager,
        serviceIsManager,
        managerLabel,
        expectedManagerPresetId,
        features
    };
}
export { getManagerDependingVariables };
